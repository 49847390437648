<template>
<div>
  <Header1></Header1>
  <Header></Header>
  <!-- banner图 -->
  <div class="banner" :style="{backgroundImage: 'url('+  getBgImgGD() +')' , height: getHeight()}">
    <div class="wrap" :style="{height: getHeight()}">
      <div class="word-wrapper">
        <span class="title">归档</span> <br>
        <span class="dec">{{date}}</span>
      </div>
    </div>
  </div>

  <div class="bigwrapper">
    <!-- 展示所有标签 -->
    <div class="tags-title"><span>Tags</span></div>
    <div class="allwrap">
      <div class="tags">
        <div class="tags-item">
          <span class="item" v-for="(tag,index) in tags" :key="index" @click="getBlogByTagId(tag.labelName)">{{tag.labelName}}</span>
        </div>
      </div>
    </div>

    <!-- 时间线模板 展示归档-->
    <!-- <div class="title-1"><span>TimeLine</span></div>
    <div class="allwrap">
      <div class="blogs">
        <div class="wrapper" v-for="(blog,index) in blogs" :key="index">
          <router-link :to="{name:'BlogDetail' , params:{blogId:blog.id}}">
            <div class="oneblogs" >
              <span class="shijian">{{blog.created}}</span>
              <span class="title">{{blog.title}}</span>
            </div>
          </router-link>
        </div>
      </div>
    </div> -->
    <!-- 时间线模板 展示归档  按年份展示-->
    <!-- <div class="title-1"><span>TimeLine</span></div> -->
        <div v-for="(item, i) in blogs" :key="i" class="wrapper">
            <!-- 年份 -->
            <div class="title-1">
                <span class="year">{{ item.year }}</span>
            </div>
            <!-- 年份下面的数据 -->
            <div class="data-wrapper">
                <div v-for="(list, j) in item.list" :key="j" class="blog-title">
                    <div class="talk-box">
                        <router-link :to="{name:'BlogDetail' , params:{blogId:list.id}}">
                            <div class="oneblogs" >
                                <span class="shijian">{{list.created.substr(5,10)}}</span>
                                <span class="title">{{list.title}}</span>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>


  </div>
  <Version></Version>
  <el-backtop class="up"> <i class="el-icon-top"></i></el-backtop>
  <!-- bottom -->
</div>
</template>


<script>
import Header from "../components/Header.vue"  ;
import Header1 from "../components/Header1.vue"  ;
import Banner from "../components/Banner.vue"  ;
import Version from "../components/Version.vue"  ;
import Dashboard from './manage/Dashboard.vue';
export default {
  name : 'Blogs' , 
  components:{Header , Header1 , Banner , Version, Dashboard} , 
  data(){
    return {
      blogs: {} , 
      currentPage:1 , 
      total:0 , 
      pageSize:99 , 
      tags:{} , 
      date:"" , 
      GDimg:"" ,
      blogs:[
        {
            "year": "2022",
            "list": [
                {
                    "id": 69,
                    "userId": 1001,
                    "title": "test",
                    "description": "test",
                    "context": "test",
                    "created": "2022-07-14",
                    "status": "0",
                    "typeId": 3,
                    "labelId": 20
                }
            ]
        }
      ]
    }
  } , 
  methods:{
    getHeight(){
      return window.innerHeight + 'px' ; 
    } , 
    // 获取列表数据
    page(currentPage){
      const _this = this ; 
      _this.$axios.get("/getAll").then(res => {
        _this.blogs = res.data.data ; 
        // console.log(_this.blogs);
      }) ; 
    } ,
    // 获取标签
    getTags(){
      const _this = this ; 
      _this.$axios.get("/getLabels").then(res =>{
        this.tags = res.data.data ; 
      }) ; 
    } , 
    // 根据tagID获取相应的博客
    getBlogByTagId(tag_name){
      this.$router.push({name:"Tag" , params:{tagName:tag_name}});
    } , 
    // 获取时间
    getDate() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      this.date = currentdate;
    } , 
    // 获取归档的背景图片
    getDGImg(){
      const _this = this ; 
      _this.$axios.get("/getBgGD").then(res => {
        _this.GDimg = res.data.data ; 
        // return res.data.data ; 
      }) ; 
    }  , 
    getBgImgGD(){
      const _this = this ; 
      return _this.GDimg ; 
    } , 
    // 按年份 获取列表数据
    getAll(){
      const _this = this ; 
      _this.$axios.get("/getAll1").then(res => {
        _this.blogs = res.data.data ; 
        console.log(_this.blogs);
      }) ; 
    },

  } , 
  created(){
    // this.page(this.currentPage) ; 
    this.getTags() ; 
    this.getDate() ; 
    this.getDGImg() ; 
    this.getAll() ; 
  }
}
</script>


<style scoped>
.banner{
  width: 100vw;
  height: 100vh;
  background-image: url("../img/k-on1.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  opacity: 1;
  margin-bottom: 5rem;
}
.banner .wrap{
  width: 100vw;
  height: 100vh;
  background-color: rgba( 0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
}
.banner .wrap .word-wrapper{
  position: absolute;
  top: 35%;
}
.banner .wrap .word-wrapper .title{
  color: white;
  font-size: 40px;
}
.banner .wrap .word-wrapper .dec{
  color: white;
  font-size: 15px;
}



@media all and (min-width:850px) {
  .bigwrapper{
    width: 55vw;
    /* height: 300px; */
    /* background-color: pink; */
    /* border: 1px solid red; */
    background-color: rgba(255, 255, 255, 0.8);
    margin: 0 auto;
    padding-top: 5rem;
    padding-bottom: 5rem;
    border-radius: 5px;
    margin-bottom: 10rem;
    /* position: absolute;
    top: 85rem;
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;  */
  }
  .allwrap{
    width: 54vw;
    border-radius: 5px;
    padding-top: 1rem;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 auto;
  }
  .bigwrapper .tags-title{
    font-size: 3rem;
    font-weight: 100;
    margin-bottom: .5rem;
  }
  .bigwrapper .tags{
    margin-left: 1rem;
    font-size: 1.4rem;
    /* margin-top: 1rem; */
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
  .bigwrapper .tags .item{
    display: inline-block;
    font-size: 1.4rem;
    padding: 8px 16px;
    border-radius: 20px;
  }
  .bigwrapper .tags .item:hover{
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
  }
  
  
  .bigwrapper .title-1{
    font-size: 3rem;
    font-weight: 100;
    margin-top: 3rem;
  }
  /* .bigwrapper{
    margin-top: 20px;
    padding-bottom: 100px;
    background-color: white;
  
    margin-bottom: 60px;
  } */
  
  .data-wrapper{
    margin: 1rem;
    padding-top: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: baseline;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.5);
  }
  .oneblogs{
    width: 50vw;
    height: 3rem;
    line-height: 30px;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    /* padding: 5px 0 5px 0 ; */
    margin-bottom: 10px;
    color: #333;
    font-size: 1.4rem;
    margin-left: 1rem;
  }
  
  .oneblogs:hover{
    background-color: rgba(255, 255, 255, 0.7);
  }
  
  .oneblogs .shijian{
    margin-right: 1.2rem;
  }
}

@media all and (max-width:850px) {
  .bigwrapper{
    width: 96vw;
    /* height: 300px; */
    /* background-color: pink; */
    /* border: 1px solid red; */
    /* position: absolute;
    top: 85rem;
    left: 0; 
    right: 0;  */
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.5);
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 10rem;
    border-radius: 5px;    
    /* margin-left: auto; 
    margin-right: auto;  */
  }
  .allwrap{
    width: 92vw;
    border-radius: 5px;
    padding-top: 1rem;
    background-color: rgba(255, 255, 255, 0.8);
    margin: 0 auto;
  }
  .bigwrapper .tags-title{
    font-size: 3rem;
    font-weight: 100;
    margin-bottom: .5rem;
  }
  .bigwrapper .tags{
    margin-left: 1rem;
    font-size: 1.4rem;
    /* margin-top: 1rem; */
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 1rem;
  }
  .bigwrapper .tags .item{
    display: inline-block;
    font-size: 1.4rem;
    padding: 8px 16px;
    border-radius: 20px;
  }
  .bigwrapper .tags .item:hover{
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
  }
  
  
  .bigwrapper .title-1{
    font-size: 3rem;
    font-weight: 100;
    margin-top: 3rem;
  }
  /* .bigwrapper{
    margin-top: 20px;
    padding-bottom: 100px;
    background-color: white;
  
    margin-bottom: 60px;
  } */
  
  .data-wrapper{
    margin: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: baseline;
    border-radius: 5px;
    box-shadow: 1px 4px 15px rgb(125 147 178 / 32%);
    transition: all .3s;
    background: rgba(255, 255, 255, 0.8);
  }

  .oneblogs{
    width: 80vw;
    /* height: 3rem; */
    line-height: 25px;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
    /* padding: 5px 0 5px 0 ; */
    margin-bottom: 10px;
    color: #333;
    font-size: 1.4rem;
    margin-left: 1rem;
  }
  
  .oneblogs:hover{
    background-color: rgba(255, 255, 255, 0.7);
  }
  
  .oneblogs .shijian{
    margin-right: 1.2rem;
  }
}

.up{
  background-color: rgba(255, 255, 255, 0.5);
}
</style>